<template><component :is="'Fleche' + fleche" class="fleche"/></template>

<style lang="scss" scoped>
.fleche {
  height: 40px;
}
</style>

<script>
export default {
  name: 'IconeGens',

  data() {
    return {
      fleche: this.randBetween(1, 5),
    };
  },

  components: {
    Fleche1: () => import('./inline.fleche_1.svg'),
    Fleche2: () => import('./inline.fleche_2.svg'),
    Fleche3: () => import('./inline.fleche_3.svg'),
    Fleche4: () => import('./inline.fleche_4.svg'),
    Fleche5: () => import('./inline.fleche_5.svg'),
  },
}
</script>
